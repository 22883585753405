@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 99;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid #1c7881;
  background-color: white;
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: #1c7881;
  stroke-width: 2.5px;

  @media (min-width: 768px) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.loader {
  width: 100%;
  margin: auto;
}

.input {
  flex-grow: 1;
  height: 50px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
  /*  color: red; */
  /*   background-color: red; */
  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: none;
    outline: none;
  }
}
.iconSvg {
  margin: auto;
}

.iconGlassSearchBtn {
  stroke: var(--iconColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: 0px;
  top: 50px;
  left: 0;
  background-color: #1c7881;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  z-index: --51;
  /* padding-bottom: 0px; */
}

.predictionsRootGoogle {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  padding-bottom: 72px;
}

.predictionsRootMapbox {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  padding-bottom: 8px;
  translate: 0 22px;
  border-radius: 10px;
  width: 280px;
  background-color: white;
  @media screen and (--viewportMedium) {
    width: 400px;
  }
  @media screen and (max-width: 600px) {
    translate: 10px 22px;
  }
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left 36px;
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;
  /*   background-color: red; */
  margin: 0;
  padding: 14px 0;

  & li {
    color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);
    margin: 0; /* Remove the double white space */
    line-height: 24px;

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 9px 24px;

    @media (min-width: 768px) {
      color: var(--marketplaceColor);
      padding: 10px 36px;
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      transition: border-left 0.4s ease;
      border-left: 5px solid var(--marketplaceColorDark);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: var(--marketplaceColorDark);
    }
  }
}

.currentLocation {
  composes: p from global;
}

.currentLocationIcon {
  margin-right: 10px;
  margin-bottom: 1px;
}

.locationSearchBtn {
  background-color: #eeebe7;
  background-repeat: repeat;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 17px 0 17px 0;
  @media (min-width: 768px) {
    padding: 17px 0 17px 0;
  }

  /* Colors */
  background-color: #eb7242;
  color: #ffffff;

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all ease-in-out 0.1s;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: #353535;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #d3d3d3;
    color: #ffffff;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (min-width: 768px) {
    min-height: 61px;
  }
  border: none;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  margin-left: 20px;
  z-index: 0;
  position: relative;
  animation: startAnimation 3s;
  width: 180px;
  height: 61px;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  cursor: pointer;
  @media (min-width: 768px) {
    position: relative;
    width: 180px;
  }
}

@media screen and (max-width: 576px) {
  .locationSearchBtn {
    margin-left: 0;
    width: 20vw;
    height: 61px;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}

@media screen and (min-width: 577px) and (max-width: 992px) {
  .locationSearchBtn {
    margin-left: 0;
    width: auto;
    height: 61px;
    padding: 10px;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}

.container {
  display: flex;
}

@media screen and (max-width: 576px) {
  .container {
    flex-direction: row;
  }
}

.searchButton {
  cursor: pointer;
  position: absolute;
  right: 0px;
  transform: translateX(-0.5px);
  z-index: 999;
  background-color: var(--marketplaceColorSecond);
  color: white;
  border: none;
  border-left: 1px solid black;
  border-radius: 0px 5px 5px 0px;
  margin-left: 10px;
  width: 100px;
  height: 49px;
  margin-top: -6px;
  &:hover {
    transition: background-color 0.4s ease;
    background-color: #d8684c;
  }

  &:disabled {
    background-color: #d3d3d3;
    color: #ffffff;
    cursor: not-allowed;
    &:hover {
      background-color: #d3d3d3;
      color: #ffffff;
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
  @media screen and (max-height: 900px) {
    height: 49px;
    margin-top: -7px;
  }
}
